<template>
  <app-header></app-header>
  <section class="miru-wonderland">
    <div class="background"></div>
    <section class="miru_main_visual">
      <div class="main_visual_box" v-if="!isMobile">
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland1920x972_06.png" alt="" />
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland1920x972_04.png" alt="" class="main_visual_Miru" />
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland1920x972_03.png" alt="" class="main_visual_tofu_R" />
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland1920x972_05.png" alt="" class="main_visual_tofu_F" />
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland1920x972_01.png" alt="" />
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland1920x972_02.png" alt="" class="main_visual_logo" data-ani="bounceIn" />
      </div>
      <div class="main_visual_box" v-else>
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland900x1475_03.png" alt="" />
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland900x1475_02.png" alt="" class="main_visual_Miru" />
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland900x1475_01.png" alt="" class="" />
      </div>

      <div v-if="!isMobile" class="main_visual_BGHeight">
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland1920x972_06.png" alt="" data-ani="bounceIn" />
      </div>
      <div v-else class="main_visual_BGHeight">
        <img src="@/assets/img/event/miruWonderland/maim_visual/miru_wonderland900x1475_03.png" alt="" />
      </div>
    </section>
    <section class="">
      <div class="exclusive_Merchandise">
        <picture>
          <source media="(max-width: 576px)" :srcset="`https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/${currentLanguage}/BEATDAY_Exclusive_Mobile.jpg`" />
          <img :src="`https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/${currentLanguage}/BEATDAY Exclusive.jpg`" alt="exclusive_Merchandise" />
        </picture>
        <img :src="`https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/${currentLanguage}/SoldOut.png`" alt="SoldOut" class="sold-out" />
      </div>
      <div class="miru-feature">
        <div id="swiper-model">
          <div v-for="(slide, index) in slides" :key="index" class="slide-item">
            <template v-if="slide.type === 'video'">
              <video :src="slide.src" loop="loop" muted="muted" autoplay="autoplay" playsinline="playsinline" draggable="true" class="responsive-video"></video>
            </template>
            <template v-else-if="slide.type === 'image'">
              <img :src="slide.src" class="responsive-video" />
            </template>
            <div class="feature_content_new">
              <div class="sticker">
                <p>{{ slide.description }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="on_live">
          <p>
            <i class="bi bi-broadcast"></i>
            {{ $t('miru-wonderland.key001-1') }}
          </p>
        </div>
        <div class="feature_info frame_border">
          <p>{{ $t('miru-wonderland.key002') }}</p>
        </div>
        <transition name="fade" appear>
          <div class="buyTicketLink" :class="{ buyFooterPosition: isFooterVisible }" v-show="buyTicketLink">
            <router-link :to="`/${currentLanguage}/product-info/65f583e5-6858-4865-87d9-a422a7900ccc`" target="_blank">
              <img src="@/assets/img/event/miruWonderland/money_alpha_002.gif" class="cat Event_Miru_btn" />
              <p>{{ $t('miru-wonderland.key003') }}</p>
            </router-link>
          </div>
        </transition>
      </div>
    </section>
    <section class="frame_wrapper">
      <div class="show_info">
        <h1 class="title_en">{{ $t('miru-wonderland.key004') }}</h1>
        <h2 class="title_tw">{{ $t('miru-wonderland.key005') }}</h2>
        <div class="info_frame free_show_info">
          <div class="info_box">
            <div class="left">
              <img :src="`https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/${currentLanguage}/EP00_0929.jpg`" alt="" />
              <div class="show_name">{{ $t('miru-wonderland.key006') }}</div>
            </div>
          </div>
          <div class="show_time">09/29</div>
        </div>

        <div v-for="(event, index) in events" :key="index" class="info_frame free_show_info no_guest">
          <div class="info_box">
            <div class="left">
              <img :src="event.image" alt="" />
              <div class="show_name">{{ event.show_name }}</div>
            </div>
          </div>
          <div class="show_time">{{ event.show_time }}</div>
        </div>

        <div class="info_frame" v-for="(info, index) in infoFrames" :key="index">
          <div class="info_box">
            <div class="left">
              <img :src="info.leftImage" alt="" />
            </div>
            <div class="right">
              <h3>{{ $t('miru-wonderland.key007') }}</h3>
              <div class="guest_box">
                <div class="guest_list" v-for="(guest, guestIndex) in info.guests" :key="guestIndex">
                  <img :src="guest.image" alt="" />
                  <p>{{ guest.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="show_name">{{ info.showName }}</div>
          <div class="miru_tofu">
            <img src="@/assets/img/event/miruWonderland/Miru_tofu02.png" alt="" />
          </div>
          <div class="show_time">{{ info.showTime }}</div>
        </div>
        <div class="info_frame free_show_info no_guest">
          <div class="info_box">
            <div class="left">
              <img src="@/assets/img/event/miruWonderland/show_none.png" alt="" />
              <div class="show_name">{{ $t('miru-wonderland.key008') }}</div>
            </div>
          </div>
          <div class="show_time">12/07</div>
        </div>
      </div>
    </section>
    <section class="frame_wrapper">
      <div class="tickets">
        <h1 class="title_en">{{ $t('miru-wonderland.key009') }}</h1>
        <h2 class="title_tw">{{ $t('miru-wonderland.key010') }}</h2>
        <div class="flex Mobile_flex_column">
          <div class="blue_box">
            <h3 class="ticket_title">{{ $t('miru-wonderland.key011') }}</h3>
            <div class="time">
              <div class="time01">
                <div class="free">
                  <p>09/29</p>
                </div>
                <div>
                  <p>10/12</p>
                </div>
                <div>
                  <p>10/26</p>
                </div>
              </div>
              <div class="time02">
                <div>
                  <p>11/10</p>
                </div>
                <div>
                  <p>11/23</p>
                </div>
                <div>
                  <p>12/07</p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex_column">
            <div class="blue_box">
              <h3 class="ticket_title">{{ $t('miru-wonderland.key012') }}</h3>
              <p>19:00 - 21:00</p>
            </div>
            <div class="blue_box">
              <h3 class="ticket_title">{{ $t('miru-wonderland.key013') }}</h3>
              <p>20:00 - 21:00</p>
            </div>
          </div>
        </div>
        <div class="pink_box flex_column">
          <img :src="`https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/${currentLanguage}/SoldOut.png`" alt="SoldOut" class="sold-out" />
          <h3 class="ticket_title">{{ $t('miru-wonderland.key014') }}</h3>
          <div class="price flex">
            <img src="@/assets/img/event/miruWonderland/price_899.png" alt="" />
            <p>{{ $t('miru-wonderland.key015') }}</p>
          </div>
          <p>{{ $t('miru-wonderland.key016-1') }}</p>
          <div class="flex item_info Mobile_flex_column">
            <div class="flex_column flex_item">
              <div class="item_box">
                <img src="@/assets/img/event/miruWonderland/item_01.png" alt="" class="item_border" />
                <p class="item_name">{{ $t('miru-wonderland.key017') }}</p>
              </div>
              <div class="item_box">
                <div class="item_frame">
                  <h3 class="item_imgName">{{ $t('miru-wonderland.key018') }}</h3>
                  <img src="@/assets/img/event/miruWonderland/item_02.png" alt="" class="auto" />
                </div>
                <p class="item_name">{{ $t('miru-wonderland.key019') }}</p>
              </div>
              <div class="item_box">
                <img src="@/assets/img/event/miruWonderland/item_03.png" alt="" />
                <p class="item_name">{{ $t('miru-wonderland.key020') }}</p>
              </div>
            </div>
            <div class="flex_item">
              <div class="item_box">
                <div class="item_frame">
                  <h3 class="item_imgName">{{ $t('miru-wonderland.key021') }}</h3>
                  <div class="item_img">
                    <p class="item_detailName">{{ $t('miru-wonderland.key022') }}</p>
                    <img src="@/assets/img/event/miruWonderland/miru_wonderland_pantsu stand_white.jpg" alt="" />
                    <p class="item_detailName">{{ $t('miru-wonderland.key023') }}</p>
                    <img src="@/assets/img/event/miruWonderland/miru_wonderland_pantsu stand_black.jpg" alt="" />
                  </div>
                </div>
                <p class="item_name">{{ $t('miru-wonderland.key024') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="pink_box flex_column">
          <img :src="`https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/${currentLanguage}/SoldOut.png`" alt="SoldOut" class="sold-out" />
          <h3 class="ticket_title">{{ $t('miru-wonderland.key025') }}</h3>
          <div class="price flex">
            <img src="@/assets/img/event/miruWonderland/price_699.png" alt="" />
            <p>{{ $t('miru-wonderland.key015') }}</p>
          </div>
          <p>{{ $t('miru-wonderland.key016-1') }}</p>
          <div class="flex item_info item_info02 Mobile_flex_column">
            <div class="item_box">
              <img src="@/assets/img/event/miruWonderland/item_01.png" alt="" class="item_border" />
              <p class="item_name">{{ $t('miru-wonderland.key017') }}</p>
            </div>
            <div class="item_box">
              <div class="item_frame">
                <h3 class="item_imgName">{{ $t('miru-wonderland.key018') }}</h3>
                <img src="@/assets/img/event/miruWonderland/item_02.png" alt="" class="auto" />
              </div>
              <p class="item_name">{{ $t('miru-wonderland.key019') }}</p>
            </div>
            <div class="item_box">
              <img src="@/assets/img/event/miruWonderland/item_03.png" alt="" />
              <p class="item_name">{{ $t('miru-wonderland.key020') }}</p>
            </div>
          </div>
        </div>
        <div class="pink_box flex_column">
          <h3 class="ticket_title">{{ $t('miru-wonderland.key026') }}</h3>
          <div class="price flex">
            <img src="@/assets/img/event/miruWonderland/price_200.png" alt="" />
            <p>{{ $t('miru-wonderland.key015') }}</p>
          </div>

          <p>{{ $t('miru-wonderland.key016') }}</p>
          <div class="flex item_info item_info02 Mobile_flex_column">
            <div class="item_box">
              <img src="@/assets/img/event/miruWonderland/item_07.png" alt="" class="item_border" />
              <p class="item_name">{{ $t('miru-wonderland.key027') }}</p>
            </div>
            <div class="item_box">
              <img src="@/assets/img/event/miruWonderland/item_06.png" alt="" />
              <p class="item_name">{{ $t('miru-wonderland.key028') }}</p>
            </div>
          </div>
        </div>
        <div class="flex Mobile_flex_column">
          <div class="flex_column yellow_box">
            <h3 class="ticket_title">{{ $t('miru-wonderland.key029') }}</h3>
            <div class="price flex">
              <img src="@/assets/img/event/miruWonderland/price_150.png" alt="" />
              <p>{{ $t('miru-wonderland.key015') }}</p>
            </div>
            <p>{{ $t('miru-wonderland.key016') }}</p>
            <div class="item_info">
              <div class="item_box">
                <img src="@/assets/img/event/miruWonderland/item_06.png" alt="" />
                <p class="item_name">{{ $t('miru-wonderland.key028') }}</p>
              </div>
            </div>
          </div>
          <div class="flex_column">
            <div class="flex_column yellow_box">
              <h3 class="ticket_title">{{ $t('miru-wonderland.key030') }}</h3>
              <div class="price flex">
                <img src="@/assets/img/event/miruWonderland/price_100.png" alt="" />
                <p>{{ $t('miru-wonderland.key015') }}</p>
              </div>
              <p>{{ $t('miru-wonderland.key031') }}</p>
            </div>
            <div class="flex yellow_box bonus_box Mobile_flex_column">
              <div class="left">
                <h3 class="title">{{ $t('miru-wonderland.key032') }}</h3>
                <p>{{ $t('miru-wonderland.key033') }}</p>
                <router-link :to="`/${currentLanguage}/product-info/65f583e5-6858-4865-87d9-a422a7900ccc`" target="_blank">
                  <p class="buy Event_Miru_btn">{{ $t('miru-wonderland.key003') }}</p>
                </router-link>
              </div>
              <div class="right">
                <img src="@/assets/img/event/miruWonderland/item_08.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <router-link :to="`/${currentLanguage}/product-info/65f583e5-6858-4865-87d9-a422a7900ccc`" target="_blank" class="buy_link flex Event_Miru_btn">
          <picture>
            <source media="(max-width: 576px)" srcset="@/assets/img/event/miruWonderland/cat_icon_02.png" />
            <img src="@/assets/img/event/miruWonderland/cat_icon.png" alt="" class="banner_logo Event_Miru_btn" />
          </picture>
          <p class="Event_Miru_btn">{{ $t('miru-wonderland.key034') }}</p>
          <picture>
            <source media="(max-width: 576px)" srcset="@/assets/img/event/miruWonderland/cat_icon_02.png" />
            <img src="@/assets/img/event/miruWonderland/cat_icon.png" alt="" class="banner_logo Event_Miru_btn" />
          </picture>
        </router-link>
        <router-link :to="`/${currentLanguage}/downloads`" class="buy_link flex download_link Mobile_flex_column" target="_blank">
          <img src="@/assets/img/headerFooter/BEATDAY_white.svg" alt="" />
          <p>{{ $t('miru-wonderland.key035') }}</p>
        </router-link>
      </div>
    </section>

    <section class="frame_wrapper">
      <div class="frame_border notice green_frame">
        <h1 class="title_en">{{ $t('miru-wonderland.key036') }}</h1>
        <h2 class="title_tw">{{ $t('miru-wonderland.key037') }}</h2>
        <ul class="notice_list">
          <li>{{ $t('miru-wonderland.key038') }}</li>
          <li v-html="formattedKey039"></li>
          <li>{{ $t('miru-wonderland.key040') }}</li>
          <li v-html="formattedKey041"></li>
          <li>{{ $t('miru-wonderland.key043') }}</li>
          <li>{{ $t('miru-wonderland.key044') }}</li>
          <li>{{ $t('miru-wonderland.key045') }}</li>
          <li>{{ $t('miru-wonderland.key046') }}</li>
          <li>{{ $t('miru-wonderland.key047') }}</li>
        </ul>
      </div>
    </section>
  </section>
  
  <app-footer ref="footerRef"></app-footer>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';

export default {
  name: 'MiruWonderland',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
  },
  props: {},
  data() {
    return {
      isAlertVisible: true,
      slides: [
        {
          type: 'video',
          description: this.$t('miru-wonderland.key050'),
          src: 'https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/MiruFeature_01_ImmersiveExperience.mp4',
        },
        {
          type: 'video',
          description: this.$t('miru-wonderland.key051'),
          src: 'https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/MiruFeature_03_SurpriseIsEverywhere.mp4',
        },
        {
          type: 'video',
          description: this.$t('miru-wonderland.key052'),
          src: 'https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/MiruFeature_04_JoinMiruWorld.mp4',
        },
        {
          type: 'video',
          description: this.$t('miru-wonderland.key053'),
          src: 'https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/MiruFeature_02_PKWithFriends.mp4',
        },
        {
          type: 'image',
          description: this.$t('miru-wonderland.key054'),
          src: require('@/assets/img/event/miruWonderland/MiruFeature_05_RealTimeVoting.jpg'),
        },
        {
          type: 'image',
          description: this.$t('miru-wonderland.key055'),
          src: require('@/assets/img/event/miruWonderland/MiruFeature_06_ PursueHonor.jpg'),
        },
      ],
      infoFrames: [
        {
          leftImage: require('@/assets/img/event/miruWonderland/show_none.png'),
          guests: [
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key056'),
            },
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key057'),
            },
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key058'),
            },
          ],
          showName: this.$t('miru-wonderland.key059'),
          showTime: '10/26',
        },
        {
          leftImage: require('@/assets/img/event/miruWonderland/show_none.png'),
          guests: [
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key060'),
            },
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key061'),
            },
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key062'),
            },
          ],
          showName: this.$t('miru-wonderland.key063'),
          showTime: '11/10',
        },
        {
          leftImage: require('@/assets/img/event/miruWonderland/show_none.png'),
          guests: [
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key064'),
            },
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key065'),
            },
            {
              image: require('@/assets/img/event/miruWonderland/guest_none.png'),
              name: this.$t('miru-wonderland.key066'),
            },
          ],
          showName: this.$t('miru-wonderland.key067'),
          showTime: '11/23',
        },
      ],
      buyTicketLink: false,
      activeAccordion: null,
      isFooterVisible: false,
      isMobile: window.innerWidth < 574,
    };
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.slick();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },

  computed: {
    formattedKey039() {
      return this.$t('miru-wonderland.key039', {
        beatday_line: `<a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053" target="_blank">@beatday</a>`,
        bratday_mail: `<a href="mailto:contact@beatday.com">contact@beatday.com</a>`,
      });
    },
    formattedKey041() {
      return this.$t('miru-wonderland.key041', {
        recommended: `<a href='#/${this.currentLanguage}/faq/redemption-guide' target="_blank">
        ${this.$t('miru-wonderland.key042')}
        </a>`,
      });
    },
    events() {
      return [
        {
          image: `https://d1sldy51c97en8.cloudfront.net/image/EventPage/02_MiruWorderland/${this.currentLanguage}/EP01_1012.jpg`,
          show_name: this.$t('miru-wonderland.key068'),
          show_time: '10/12',
        },
      ];
    },
  },
  methods: {
    slick() {
      $('#swiper-model').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        arrows: false,
        prevArrow: true,
        nextArrow: true,
      });
    },
    handleResize() {
      this.isMobile = window.innerWidth < 574;
    },
    handleScroll() {
      if (!this.isMobile) {
        //main_img_scale
        // const image = this.$refs.scrollImage;
        // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        // const maxScroll = window.innerHeight / 2;
        // let scale = 1.01 - (scrollTop / maxScroll) * 0.1;

        // if (scale < 0.9) scale = 0.9;

        // image.style.transform = `scale(${scale})`;

        //buyTicketBtn
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;

        if (scrollPosition >= 200) {
          this.buyTicketLink = true;
        } else {
          this.buyTicketLink = false;
        }

        const footer = this.$refs.footerRef.$el;
        const footerRect = footer.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        const space = footerRect.top - viewportHeight;

        if (space <= 0) {
          this.isFooterVisible = true;
        } else {
          this.isFooterVisible = false;
        }
      } else {
        this.buyTicketLink = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/pages/event/miruWonderland.scss';
</style>
